<template>
	<div class="container">
		<bread-crums :nesting="['Dental Care', 'Root Canal Treatment']"></bread-crums>
		<intro button-text="Contact Us" button-link="/contact" title="Root Canal Treatment" :content="paragraphs"/>
		<div class="pgcontent">
			<div v-for="card in cards" :key="card.title" class="card">
				<h2>{{card.title}}</h2>
				<p>{{card.content}}</p>
			</div>
      <div v-for="section in sections" :key="section.title">
        <p>{{ section }}</p>
      </div>
			<div class="table">
				<price-table :procedure="Procedures['categories'][1]"></price-table>
			</div>
		</div>      
	</div>
</template>

<script>
import Intro from '../components/Intro.vue'
import PriceTable from '../components/PriceTable.vue'
import  Procedures  from "../assets/json/procedures.json";
import BreadCrums from '../components/BreadCrums.vue';
export default {
	name: 'GeneralDentisty',
	components: {
		Intro,
		PriceTable,
		BreadCrums
	},
	data(){
		return{
			Procedures,
			paragraphs: [
          "This is where more persistent toothache, infection or discomfort is treated by accessing the deeper part of your tooth – the dental pulp (nerve tissue). Over time, it may have become infected or the decay may have progressed, causing pain. This treatment is often effective in removing the pain. To restore the root canal, the dentist will place a biocompatible restorative material to preserve and protect the tooth, preventing repeated infection. We may need to restore the tooth with a crown to resist breakage and wear on the tooth over time. Rest assured your dentist will advise and guide you through the stages of treatment and ensure you are looked after."
			],
			cards:[
			]
		}
	}
}
</script>

<style scoped>
	.pgcontent {
		background-color: #FFF;
		padding: 1.5rem;
	}
	h2{
		width: 95%;
		color: var(--primary)
	}
	@media screen and (min-width: 375px){
		.pgcontent{
			padding: 1.5rem 2.5rem;
		}
	}
	@media screen and (min-width: 768px){
		.pgcontent{
			padding: 1.5rem6rem;
		}	
	}
	@media screen and (min-width: 1024px) {
		
		.container{
			padding-top: 1rem;
		}
		.pgcontent{
			display: flex;
			flex-wrap: wrap;
			padding: 2rem var(--padding1);
			justify-content: center;
		}
		.card{
			max-width: 50%;			
		}
		p{
			width: 70%;
		}
		.table{
			width: 100%;
		}
		
	}
	@media screen and (min-width: 1440px) {		
		.pgcontent{
			padding: 2rem var(--padding2);
		}    
	}
	@media screen and (min-width: 1750px){
		.pgcontent{
			padding: 2rem 4rem;
		}    
	}
</style>